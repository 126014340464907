import React from 'react'
import { MDBCol, MDBRow, MDBContainer } from 'mdbreact'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faFax, faEnvelope, faInfoCircle } from '@fortawesome/pro-solid-svg-icons'

class CardIconsOffices extends React.Component {
  render() {
    return (
      <section className="bg-light-blue">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12" className="pr-5 pb-5">
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-4">
                HQ contacts
                <span id="hq-contacts" className="anchor">
                  &nbsp;
                </span>
              </h2>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol lg="4" md="6" className="pb-30 d-flex">
              <div className="card flex-fill outline card-hover">
                <div
                  className="card-body d-flex flex-row align-items-center"
                  style={{ minHeight: '150px', maxHeight: '150px' }}
                >
                  <div
                    style={{
                      minWidth: '85px',
                      maxWidth: '85px',
                    }}
                  >
                    <StaticImage
                      src={'../../static/assets/contact-sales.png'}
                      className="mr-1"
                      alt="user wearing headset icon"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3 pl-3">
                      Sales
                    </h3>
                  </div>
                </div>

                <div className="card-body-prods">
                  <ul className="fa-ul px-0 px-md-2">
                    <>
                      <li className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className="ml-2 mr-5 va-m"
                          />
                        </span>
                        <a
                          href={'mailto:sales@firetide.com'}
                          className="effect"
                          rel="noopener"
                          target="_blank"
                        >
                          sales@firetide.com
                        </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </MDBCol>

            <MDBCol lg="4" md="6" className="pb-30 d-flex">
              <div className="card flex-fill outline card-hover">
                <div
                  className="card-body d-flex flex-row align-items-center"
                  style={{ minHeight: '150px', maxHeight: '150px' }}
                >
                  <div
                    style={{
                      minWidth: '85px',
                      maxWidth: '85px',
                    }}
                  >
                    <StaticImage
                      src={'../../static/assets/contact-support.png'}
                      className="mr-1"
                      alt="lifebuoy icon"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3 pl-3">
                      Technical support
                    </h3>
                  </div>
                </div>

                <div className="card-body-prods">
                  <ul className="fa-ul text-medium">
                    <>
                      <li className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className="ml-2 mr-5 va-m"
                          />
                        </span>
                        <a
                          href={'mailto:support@firetide.com'}
                          className="effect"
                          rel="noopener"
                          target="_blank"
                        >
                          support@firetide.com
                        </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </MDBCol>
            <MDBCol lg="4" md="6" className="pb-30 d-flex">
              <div className="card flex-fill outline card-hover">
                <div
                  className="card-body d-flex flex-row align-items-center"
                  style={{ minHeight: '150px', maxHeight: '150px' }}
                >
                  <div
                    style={{
                      minWidth: '85px',
                      maxWidth: '85px',
                    }}
                  >
                    <StaticImage
                      src={'../../static/assets/contact-marketing-inquiries.png'}
                      className="mr-1"
                      alt="target icon"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3 pl-3">
                      Marketing and media inquiries
                    </h3>
                  </div>
                </div>

                <div className="card-body-prods">
                  <ul className="fa-ul text-medium">
                    <>
                      <li className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className="ml-2 mr-5 va-m"
                          />
                        </span>
                        <a
                          href={'mailto:marketing@firetide.com'}
                          className="effect"
                          rel="noopener"
                          target="_blank"
                        >
                          marketing@firetide.com
                        </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </MDBCol>

            <MDBCol lg="4" md="6" className="pb-30 d-flex">
              <div className="card flex-fill outline card-hover">
                <div
                  className="card-body d-flex flex-row align-items-center"
                  style={{ minHeight: '150px', maxHeight: '150px' }}
                >
                  <div
                    style={{
                      minWidth: '85px',
                      maxWidth: '85px',
                    }}
                  >
                    <StaticImage
                      src={'../../static/assets/contact-order-status.png'}
                      className="mr-1"
                      alt="report icon"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3 pl-2">
                      Order status
                    </h3>
                  </div>
                </div>

                <div className="card-body-prods">
                  <ul className="fa-ul text-medium">
                    <>
                      <li className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className="ml-2 mr-5 va-m"
                          />
                        </span>
                        <a
                          href={'mailto:orders@firetide.com'}
                          className="effect"
                          rel="noopener"
                          target="_blank"
                        >
                          orders@firetide.com
                        </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </MDBCol>

            <MDBCol lg="4" md="6" className="pb-30 d-flex">
              <div className="card flex-fill outline card-hover">
                <div
                  className="card-body d-flex flex-row align-items-center"
                  style={{ minHeight: '150px', maxHeight: '150px' }}
                >
                  <div
                    style={{
                      minWidth: '85px',
                      maxWidth: '85px',
                    }}
                  >
                    <StaticImage
                      src={'../../static/assets/contact-careers.png'}
                      className="mr-1"
                      alt="users as part of a cog icon"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3 pl-3">
                      Careers
                    </h3>
                  </div>
                </div>

                <div className="card-body-prods">
                  <ul className="fa-ul text-medium">
                    <>
                      <li className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="ml-2 mr-5 va-m"
                          />
                        </span>
                        See our{' '}
                        <a
                          href="https://www.linkedin.com/company/firetide-inc"
                          className="effect"
                          target="_blank"
                        >
                          current opportunities
                        </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </MDBCol>

            <MDBCol lg="4" md="6" className="pb-30 d-flex">
              <div className="card flex-fill outline card-hover">
                <div
                  className="card-body d-flex flex-row align-items-center"
                  style={{ minHeight: '150px', maxHeight: '150px' }}
                >
                  <div
                    style={{
                      minWidth: '85px',
                      maxWidth: '85px',
                    }}
                  >
                    <StaticImage
                      src={'../../static/assets/contact-general-inquiries.png'}
                      className="mr-1"
                      alt="world icon"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3 pl-3">
                      General inquiries
                    </h3>
                  </div>
                </div>

                <div className="card-body-prods">
                  <ul className="fa-ul text-medium">
                    <>
                      <li className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className="ml-2 mr-5 va-m"
                          />
                        </span>
                        <a
                          href={'mailto:info@firetide.com'}
                          className="effect"
                          rel="noopener"
                          target="_blank"
                        >
                          info@firetide.com
                        </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    )
  }
}

export default CardIconsOffices
